/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('./src/styles/global.css')

exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {

    const currentPosition = getSavedScrollPosition(location);
    window.scrollTo(...(currentPosition || [0, 0]))
    return false
}