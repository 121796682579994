exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tree-ring-corporation-index-js": () => import("./../../../src/pages/About-TreeRing-Corporation/index.js" /* webpackChunkName: "component---src-pages-about-tree-ring-corporation-index-js" */),
  "component---src-pages-contact-treering-index-js": () => import("./../../../src/pages/Contact-Treering/index.js" /* webpackChunkName: "component---src-pages-contact-treering-index-js" */),
  "component---src-pages-covid-19-pandemic-yearbook-help-index-js": () => import("./../../../src/pages/covid19-pandemic-yearbook-help/index.js" /* webpackChunkName: "component---src-pages-covid-19-pandemic-yearbook-help-index-js" */),
  "component---src-pages-custom-yearbooks-js": () => import("./../../../src/pages/Custom-Yearbooks.js" /* webpackChunkName: "component---src-pages-custom-yearbooks-js" */),
  "component---src-pages-idea-yearbook-index-js": () => import("./../../../src/pages/idea-yearbook/index.js" /* webpackChunkName: "component---src-pages-idea-yearbook-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketing-yearbook-index-js": () => import("./../../../src/pages/marketing-yearbook/index.js" /* webpackChunkName: "component---src-pages-marketing-yearbook-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-request-complete-index-js": () => import("./../../../src/pages/request-complete/index.js" /* webpackChunkName: "component---src-pages-request-complete-index-js" */),
  "component---src-pages-request-demo-index-js": () => import("./../../../src/pages/request-demo/index.js" /* webpackChunkName: "component---src-pages-request-demo-index-js" */),
  "component---src-pages-request-sample-index-js": () => import("./../../../src/pages/request-sample/index.js" /* webpackChunkName: "component---src-pages-request-sample-index-js" */),
  "component---src-pages-request-trial-index-js": () => import("./../../../src/pages/request-trial/index.js" /* webpackChunkName: "component---src-pages-request-trial-index-js" */),
  "component---src-pages-sample-yearbook-elementary-school-js": () => import("./../../../src/pages/sample-yearbook/elementary-school.js" /* webpackChunkName: "component---src-pages-sample-yearbook-elementary-school-js" */),
  "component---src-pages-sample-yearbook-high-school-js": () => import("./../../../src/pages/sample-yearbook/high-school.js" /* webpackChunkName: "component---src-pages-sample-yearbook-high-school-js" */),
  "component---src-pages-sample-yearbook-middle-school-js": () => import("./../../../src/pages/sample-yearbook/middle-school.js" /* webpackChunkName: "component---src-pages-sample-yearbook-middle-school-js" */),
  "component---src-pages-thank-you-index-js": () => import("./../../../src/pages/thank-you/index.js" /* webpackChunkName: "component---src-pages-thank-you-index-js" */),
  "component---src-pages-tree-ring-copyright-policy-index-js": () => import("./../../../src/pages/TreeRing-Copyright-Policy/index.js" /* webpackChunkName: "component---src-pages-tree-ring-copyright-policy-index-js" */),
  "component---src-pages-tree-ring-frequently-asked-questions-index-js": () => import("./../../../src/pages/TreeRing-Frequently-Asked-Questions/index.js" /* webpackChunkName: "component---src-pages-tree-ring-frequently-asked-questions-index-js" */),
  "component---src-pages-tree-ring-privacy-statement-index-js": () => import("./../../../src/pages/TreeRing-Privacy-Statement/index.js" /* webpackChunkName: "component---src-pages-tree-ring-privacy-statement-index-js" */),
  "component---src-pages-tree-ring-terms-of-use-index-js": () => import("./../../../src/pages/TreeRing-Terms-of-Use/index.js" /* webpackChunkName: "component---src-pages-tree-ring-terms-of-use-index-js" */),
  "component---src-pages-which-yearbook-company-js": () => import("./../../../src/pages/Which-Yearbook-Company.js" /* webpackChunkName: "component---src-pages-which-yearbook-company-js" */),
  "component---src-pages-yearbook-themes-guide-index-js": () => import("./../../../src/pages/Yearbook-Themes-Guide/index.js" /* webpackChunkName: "component---src-pages-yearbook-themes-guide-index-js" */),
  "component---src-templates-word-press-post-js": () => import("./../../../src/templates/wordPressPost.js" /* webpackChunkName: "component---src-templates-word-press-post-js" */)
}

